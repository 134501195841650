<template>
  <page :styleWrap='{background: "#fff"}'>
    <div>
      <header-bar>
        <div>看帖时间范围</div>
        <div slot='action' class="theme_c" @click='$router.back()'>取消</div>
      </header-bar>
      <div class="timeItem f_flex f_a_c f_j_sb underline" @click='pickItem(1)'>
        <p>全部</p>
        <i v-if='act===1' class="iconfont icon-check1 theme_c"></i>
      </div>
      <div class="timeItem f_flex f_a_c f_j_sb underline" @click='pickItem(2)'>
        <p>半年</p>
        <i v-if='act==2' class="iconfont icon-check1 theme_c"></i>
      </div>
      <div class="timeItem f_flex f_a_c f_j_sb" :class="{underline: act!=3}" @click='pickItem(3)'>
        <p>自定义</p>
        <i v-if='act===3' class="iconfont icon-check1 theme_c"></i>
      </div>
      <div class="timeItem startTime f_flex f_a_c f_j_sb underline" v-if='act===3'>
        <p>起始时间</p>
        <div class="f_flex f_a_c f_j_e f_g1" @click='pickTime'>
          <p class="theme_c" style="margin-right: 10px">{{date}}</p>
          <i class="iconfont icon-arrow-down"></i>
        </div>
      </div>

      <button @click='submitFn' class="btn btn_radius theme_bg margin_auto rangeBtn">确认修改</button>
      
      <popup v-model="showPopup" position="bottom">
        <datetime-picker
          v-model="currentDate"
          type="date"
          title="选择起始时间"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm='confirmFn'
          @cancel='cancleFn'
          :formatter="formatter"
        />
      </popup>
    </div>
  </page>
</template>

<script>
import { DatetimePicker, Popup } from 'vant'
import groupApi from '@/api/group'

export default {
  data: ()=> ({
    act: 1,
    date: '',
    minDate: new Date(2020, 0, 1),
    maxDate: new Date(2025, 10, 1),
    currentDate: new Date(),
    showPopup: false
  }),
  components: {
    DatetimePicker,
    Popup
  },
  methods: {
    pickItem(e) {
      this.act = e
    },
    pickTime() {
      this.showPopup = true
    },
    confirmFn(e) {
      this.showPopup = false
      this.currentDate = e
      this.date = this.formatterTime(e)
    },
    cancleFn() {
      this.showPopup = false
    },
    formatter(type, val) {
      if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    formatterTime(t) {
      const date = new Date(t)
      const y = date.getFullYear()
      const m = date.getMonth()+1
      const d = date.getDate()
      return `${y}-${m}-${d}`
    },
    submitFn() {
      const obj = {
        groupId: this.$route.query.groupId,
        postsStartTimeType: this.act
      }
      if (this.act == 3) {
        if (this.date) {
          obj.customizeStartTime = this.date
        } else {
          this.$toast('请选择时间')
          return
        }
      }
      groupApi.modifyGroupInfo(obj).then(res => {
        if (res.data) {
          this.$toast(res.data.msg)
          setTimeout(() => {
            this.$router.back()
          }, 1000)
        }
      })
    }
  },
  mounted() {
    if (this.$route.query.type) {
      this.act = this.$route.query.type
    }
  }
}
</script>

<style lang="less" scoped>
.timeItem {
  margin: 0 15px;
  padding: 14px 0;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  box-sizing: border-box;
}
.icon-check1 {
  font-size: 22px;
  font-weight: 500;
}
.icon-arrow-down {
  font-size: 14px;
  color: #999;
}
.startTime {
  padding-left: 32px;
}
.rangeBtn {
  width: 80%;
  margin-top: 120px;
}
</style>