import http from './http'

import Config from './config';
const apiHeader = Config.apiUrl
export default {
  // 公司&群列表查询接口
  groupList: (data) => http.post(`${apiHeader}/session/group/queryGroupList`, {data}),
  // 群创建-查询公司部门用户
  queryDepartmentUserList: data => http.post(`${apiHeader}/session/company/queryDepartmentUserList`, {data}),
  // 群贴子信息分页查询接口
  groupPostList: data => http.post(`${apiHeader}/session/group/pageQueryPostsList`, {data}),
  // 群发布贴子接口
  groupPostPublish: data => http.post(`${apiHeader}/session/posts/publish`, {data}),
  // 群创建接口
  groupCreate: data => http.post(`${apiHeader}/session/group/create`, {data}),
  // 群成员操作接口
  groupUserOperate: data => http.post(`${apiHeader}/session/group/userOperate`, {data}),
  // 群修改信息接口
  groupModifyInfo: data => http.post(`${apiHeader}/session/group/modifyInfo`, {data}),
  // 群基础信息查询接口
  groupInfo: data => http.post(`${apiHeader}/session/group/queryGroupInfo`, {data}),
  // 群关闭接口
  groupClose: data => http.post(`${apiHeader}/session/group/close`, {data}),
  // 群标签查询接口
  groupLabelList: data => http.post(`${apiHeader}/session/group/queryLabelGroupList`, {data}),
  // 群创建-查询多公司信息
  queryCompanyInfoList: data => http.post(`${apiHeader}/session/company/queryCompanyInfoList`, {data}),
  //群管理员信息查询
  groupManagerInfo: data => http.post(`${apiHeader}/session/group/groupManagerInfo`, {data}),

  setGroupManager: data => http.post(`${apiHeader}/session/group/groupAssistantSetting`, {data}),
  // 群修改信息接口
  modifyGroupInfo: data => http.post(`${apiHeader}/session/group/modifyInfo`, {data}),
  // 置顶
  setTop: data => http.post(`${apiHeader}/session/group/settingTop`, {data}),
  //群动态设置列表
  groupDynamicSetList: data => http.post(`${apiHeader}/session/group/dynamic/show/list`, {data}),
  //群动态设置
  groupDynamicSet: data => http.post(`${apiHeader}/session/group/dynamic/show/settings`, {data}),
}